/* eslint-disable */
import React from 'react';
import Img from "gatsby-image";
import { Link } from "gatsby";
import sendEvent from '../helpers/sendAnalytics';

import Dots from '../layouts/images/vdots.svg'

class ProductList extends React.Component {
  render() {
    return (
      <div className="three-col-grid">
        {this.props.data.relationships.field_product.map(product => {
          if (product.id === '627378e2-b21a-5aca-9132-2cf764b913c8') {
            return null;
          }
          return (
            <div className={"column-item" + " " +product.title.replace(/\s/g, '')} key={product.id} id={product.id}>
              <div className="three-col-grid-item-wrapper" style={{backgroundImage: `url(${product.relationships.field_image.localFile.childImageSharp.resolutions.src})`}}>
                <div className="new-look-badge" />
                <div className="three-col-item-text">
                  <div className="three-col-text-title white-sans-title">
                    <div className="plys">
                      {(product.id === 'c171317c-e59d-5838-b588-de031f3e636b') ? '2':'3'} <span>PLY</span>
                    </div>
                    <img className="three-col-hr" src={Dots} alt="" />
                    <div className="product-title">
                      {product.title}
                      {(product.id === 'bb5ec40d-3e69-5466-9cca-efc4195fc4fc') ? <sup>®</sup> : null}
                      {(product.id === 'f3534e4d-130c-5d93-a64e-f18aca9518ea') ? <> triple soft<sup>®</sup></> : null}
                    </div>
                  </div>

                  <div className="section-body-text white" dangerouslySetInnerHTML={{__html: product.field_preview_description.value}}></div>
                  <div className="arrow-link">
                    <svg width="6px" height="10px" viewBox="0 0 6 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                        <g id="02-Products" transform="translate(-174.000000, -1708.000000)" stroke="#FFFFFF" strokeWidth="2">
                          <g id="Group-2-Copy-3" transform="translate(174.000000, 1387.000000)">
                            <g id="Group-5-Copy-3" transform="translate(1.000000, 316.000000)">
                              <polyline id="Shape-Copy-2" points="0 6 4 10 0 14"></polyline>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <Link
                      to={product.path.alias}
                      className="blue-arrow-link white"
                      onClick={() => sendEvent({
                        ga: {
                          category: 'Engagement',
                          action: 'Click',
                          label: 'Product_Landing_' + product.title.toLowerCase().replace(' ','_') + '_Click_Through'
                        }
                      })}
                      >
                      View Products
                    </Link>
                  </div>
                </div>
              </div>
              <div className="three-col-grid-image"></div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default ProductList
